import { createAction, props } from '@ngrx/store';

export const globalStoreSetLoginAsUser = createAction(
	'[Global Actions] Set log in as user id',
	props<{ userId: string }>()
);

export const globalStoreSetLoginAsUserSuccess = createAction(
	'[Global Actions] Successfully log in as user id',
	props<{ userId: string }>()
);

export const globalStoreLoadImpersonatedUserId = createAction(
	'[Global Actions] Load impersonated user id on startup'
);

export const globalStoreLoadImpersonatedUserIdSuccess = createAction(
	'[Global Actions] Load impersonated user id on startup successfully',
	props<{ userId: string }>()
);
